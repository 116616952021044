import { InstanceAxios } from "../hooks/axiosConfig";

export class ProductServices {
  // public static getAllProductApi() {
  //   return InstanceAxios().get("/product");
  // }
  public static getAllProductApi(page?: number, pagesize?: number) {
    return InstanceAxios().get("/product/getProductsAdmin", {
      params: {
        page: page,
        limit: pagesize,
      },
    });
  }
  public static addProductApi(
    name: string,
    HName: string,
    description: string,
    category: string
  ) {
    let data = {
      name: name,
      HName: HName,
      description: description,
      category: category,
    };
    return InstanceAxios().post("/product/addProductAdmin", data);
  }
  public static updateProductApi(
    name: string,
    description: string,
    category: string,
    _id: string
  ) {
    let data = {
      name: name,
      description: description,
      category: category,
    };
    return InstanceAxios().put(`/product/updateproduct/${_id}`, data);
  }
  public static deleteProductApi(_id: string) {
    return InstanceAxios().delete(`/product/removeproduct/${_id}`);
  }
}
