import { useEffect, useState } from "react";
import { Paper, TableBody, TablePagination } from "@mui/material";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import { PlaceOrderServices } from "../../../../services/PlaceOrder";
import useIsPlaceOrderStore from "../../../../store/isPlaceOrder";
import { TLoader } from "../../../../core/Loader";
import THeader from "./THeader";
import TRow from "./TRow";
import { SupplierServices } from "../../../../services/SupplierServices";
import { ISupplierList } from "../../../../models/Suppliers/ISupplierList";
import THeaderView from "../../../../core/THeaderView";
import { AllList, mobileList } from "../data";
import MTable from "../../../../core/MTable";
import useIsSuppleirAddedStore from "../../../../store/isDriverAdd copy";
import { ContainerService } from "../../../../services/ContainerServices";
import useIsContainerAddedStore from "../../../../store/isContainerAdded";
import { IContainer } from "../../../../models/IContainer";
import { ICtrack } from "../../../../models/ICRack";

const List = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [state1, setState1] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const { active, setActive } = useIsContainerAddedStore((state) => ({
    active: state.active,
    setActive: state.setActive,
  }));

  const [list, setList] = useState<ICtrack[] | undefined>([] as ICtrack[]);

  const getContainerList = async () => {
    setList([]);
    try {
      setState({ ...state, loader: true });
      const response = await ContainerService.getCTrackList();
      if (response.status === 200 && response.data && response.data.data) {
        setList(response.data.data);

        setState({ ...state, loader: false });
        setActive(false);
      } else {
        setState({ ...state, loader: false });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (active) {
      getContainerList();
    }
  }, [active]);

  useEffect(() => {
    getContainerList();
  }, []);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const handler = setTimeout(() => {}, 500);
    return () => clearTimeout(handler);
  }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  const handleClose1 = () => {
    if (state1.tosted) {
      setTimeout(() => {
        setState1({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  useEffect(() => {
    handleClose1();
  }, [state1.tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
          />

          <MTable>
            <THeaderView MobileList={mobileList} AllList={AllList} />
            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} />;
              })}
            </TableBody>
          </MTable>

          {loader && <TLoader />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />

      <Tosted
        label={state1.message}
        open={state1.tosted}
        severity={state1.severity}
        handleClose={handleClose1}
      />
    </>
  );
};
export default List;
