import { CircularProgress } from "@mui/material";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ChangePassword, Login } from "./pages/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import View from "./pages/shopmanger/View/View";
import {
  MShop,
  MProcurement,
  MAllocation,
  MPurchase,
} from "./components/RouteValidate";
import {
  BuyOrder,
  PMSupplier,
  PMSupplierProduct,
} from "./pages/purchaseManger";
import { Add } from "./pages/shopmanger/Add";
import { UpdateOrder } from "./pages/updateOrder";
import { AllocationOrder } from "./pages/allocationOrder";
import { ReceiveOrder } from "./pages/shopmanger/ReceiveOrder";
import { CheckOrder } from "./pages/shopmanger/Check-order";
import { Price } from "./pages/shopmanger/Price";
import { PrintPrice } from "./pages/shopmanger/Price/core/PrintPdf";
import { Navigate } from "react-router-dom";
import useTokenStore from "./store/token";
import NotFoundPage from "./components/CommonPage/NotFoundPage";
import { Procrument, ShopItem } from "./pages/procrumentManger";
import { Sales } from "./pages/shopmanger/Sales";
// import ViewSupplier from "./pages/purchaseManger/PMSupplierProduct";
import PamymentList from "./pages/purchaseManger/paymentList";
import { Return } from "./pages/shopmanger/Return";
import { Supplier, Driver, Profile } from "./components/CommonPage";
import PMDriver from "./components/CommonPage/PMDriver/PMDriver";
import { PurchaseReport } from "./pages/shopmanger/PurchaseReport";
import PMSupplierLedger from "./pages/purchaseManger/PMSupplierLedger";
import PMSupplierShopReport from "./pages/purchaseManger/PMSupplierLedger/PMSupplierShopReport";
import { OrderReport } from "./pages/shopmanger/OrderReport";
import PMPurchaseReport from "./pages/purchaseManger/PurchaseReport/PMPurchaseReport";

import { Product } from "./pages/product";
import MajDuri from "./pages/purchaseManger/MajDuri/MajDuri";
import Container from "./components/CommonPage/Container/Container";
import { POSUser } from "./pages/shopmanger/POS-User";
import PMSupplierNormalProduct from "./pages/purchaseManger/PMSupplierProduct copy";
import PMSupplierNormal from "./pages/purchaseManger/PMSupplier copy";
import ContainerTrack from "./pages/purchaseManger/ContainerTrack/ContainerTrack";
import ContainerTrackView from "./pages/purchaseManger/ContainerTrackView/ContainerTrackView";
const Dashbord = lazy(() => import("./pages/dashboard"));

const Path = () => {
  const { token } = useTokenStore((state) => ({
    token: state.token,
  }));

  return (
    <>
      <Suspense fallback={<CircularProgress disableShrink />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="/print-item"
            element={
              <Suspense fallback={<CircularProgress disableShrink />}>
                <PrintPrice />
              </Suspense>
            }
          />
          <Route
            path="/"
            index
            element={
              token ? (
                <Navigate to="/branch-manager/view" />
              ) : (
                <Navigate to={"/login"} />
              )
            }
          />
          <Route path="*" element={<NotFoundPage />} />

          <Route element={<ProtectedRoute />}>
            {/* ------------- Common Route ----------------- */}

            {/* ------------- End Comon  Route ----------------- */}

            <Route element={<MShop />}>
              <Route
                path="/branch-manager/view"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <View />
                  </Suspense>
                }
              />
              <Route
                path="/branch-manager/add"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Add />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/price"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Price />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/pos-user"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <POSUser />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/purchase-report"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PurchaseReport />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/receive-order"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <ReceiveOrder />
                  </Suspense>
                }
              />
              <Route
                path="/branch-manager/receive-order/:id"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <CheckOrder />
                  </Suspense>
                }
              />
              <Route
                path="/branch-manager/sales"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Sales />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/order-report"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    {/* <>Order Report</> */}
                    <OrderReport />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/karat"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Return />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/driver-list"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Driver />
                  </Suspense>
                }
              />

              <Route
                path="/branch-manager/suppliers"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Supplier />
                  </Suspense>
                }
              />
            </Route>

            <Route element={<MPurchase />}>
              <Route
                path="/buy-order"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <BuyOrder />
                  </Suspense>
                }
              />
              <Route
                path="/buy-order/:id"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <UpdateOrder />
                  </Suspense>
                }
              />
              <Route
                path="/supplier"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMSupplier />
                  </Suspense>
                }
              />

              <Route
                path="/normal-supplier"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMSupplierNormal />
                  </Suspense>
                }
              />

              <Route
                path="/supplier-ledger"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMSupplierLedger />
                  </Suspense>
                }
              />

              <Route
                path="/majduri-ledger"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <MajDuri />
                  </Suspense>
                }
              />

              <Route
                path="/supplier-ledger/:id"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMSupplierShopReport />
                  </Suspense>
                }
              />

              {/* PMSupplierShopReport */}

              <Route
                path="/supplier/:id"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMSupplierProduct />
                  </Suspense>
                }
              />

              <Route
                path="/normal-supplier/:id"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMSupplierNormalProduct />
                  </Suspense>
                }
              />

              <Route
                path="/purchase-manager/supplier"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Supplier />
                  </Suspense>
                }
              />

              <Route
                path="/container-list"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Container />
                  </Suspense>
                }
              />

              <Route
                path="/container-tracking"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <ContainerTrack />
                  </Suspense>
                }
              />

              <Route
                path="/container-tracking/:supplierId"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <ContainerTrackView />
                  </Suspense>
                }
              />

              <Route
                path="/purchase/report"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMPurchaseReport />
                  </Suspense>
                }
              />

              <Route
                path="/purchase-manager/driver"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <PMDriver />
                  </Suspense>
                }
              />

              <Route
                path="/product-list"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Product />
                  </Suspense>
                }
              />

              <Route
                path="/payment-list"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    {/* <>Payment list  </> */}
                    <PamymentList />
                  </Suspense>
                }
              />
            </Route>

            <Route element={<MProcurement />}>
              <Route
                path="/procurement/supplier-list"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <Procrument />
                  </Suspense>
                }
              />
              <Route
                path="/procurement/supplier-list/:shopNo/:date"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <ShopItem />
                  </Suspense>
                }
              />
            </Route>

            <Route element={<MAllocation />}>
              <Route
                path="/confirm-order"
                element={
                  <Suspense fallback={<CircularProgress disableShrink />}>
                    <AllocationOrder />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="/profile"
              element={
                <Suspense fallback={<CircularProgress disableShrink />}>
                  <Profile />
                </Suspense>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};
export default Path;
