import { useEffect, useState } from "react";
import {
  Paper,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";

import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import { TLoader } from "../../../../core/Loader";
import THeader from "./THeader";
import TRow from "./TRow";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import MTable from "../../../../core/MTable";
import THeaderView from "../../../../core/THeaderView";
import { AllList, MobileList } from "../data";
import { BranchService } from "../../../../services/Branch";
import { IPurchaseReport } from "../../../../models/Branch/IPurchaseReport";
import { Label } from "../../../../core/Label";
import { PurchaseServie } from "../../../../services/PurchaseServices";
import { IPMTotalPurchase } from "../../../../models/IPMTotalPurchase";
import * as XLSX from "xlsx";

const List = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [date, setDate] = useState<string>(getTodayDate());

  // Event handler for date change
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    console.log("Date Handler", event.target.value);
  };

  const [list, setList] = useState<IPMTotalPurchase[] | undefined>(
    [] as IPMTotalPurchase[]
  );

  const [netTotal, setNetTotal] = useState(0);

  const getOrderList = async () => {
    setList([]);
    setNetTotal(0);
    try {
      setState({ ...state, loader: true });
      const response = await PurchaseServie.totalPurchaseApi(date);
      if (
        response.data &&
        response.data.data &&
        response.data.data.rateList &&
        response.data.data.rateList.length > 0
      ) {
        setList(response.data.data.rateList);
        setNetTotal(response.data.data.total);
        setState({ ...state, loader: false });
      } else {
        setList([]);
        setState({ ...state, loader: false });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getOrderList();
  }, [rowsPerPage, page, category, date]);

  useEffect(() => {
    // Debounce implementation
    const handler = setTimeout(() => {
      // getOrderList();
    }, 500); // 2-second delay

    return () => clearTimeout(handler); // Clear timeout on component unmount or if searchInput changes again within the delay
  }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  const onDownloadExcel = () => {
    // Create a new workbook and a new worksheet
    if (list && list?.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(list ? list : []);
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Users");
      // Generate Excel file and download it
      XLSX.writeFile(wb, "purchase-report.xlsx");
    } else {
      alert("No Data is there");
    }
  };

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
            date={date}
            onDateChange={onDateChange}
            onDownloadExcel={onDownloadExcel}
          />

          <MTable>
            <THeaderView AllList={AllList} MobileList={MobileList} />

            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} />;
              })}
              <TableRow hover role="checkbox">
                <TableCell colSpan={5}></TableCell>
                <TableCell align="center">
                  <b>Net Total</b>
                </TableCell>
                <TableCell align="center">
                  <Label sx={{ fontSize: "15px" }} color={"success"}>
                    {netTotal}
                  </Label>
                </TableCell>
              </TableRow>
            </TableBody>
          </MTable>

          {loader && <TLoader />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
