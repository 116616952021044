import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Container Name",
    isMove: false,
    align: "center",
  },
  {
    id: 1,
    name: "Current Balance",
    isMove: false,
    align: "center",
  },

  {
    id: 1,
    name: "Total Received",
    isMove: false,
    align: "center",
  },

  {
    id: 1,
    name: "Total Returned",
    isMove: false,
    align: "center",
  },

  {
    id: 2,
    name: "Return",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const mobileList: ITHead[] = [
  {
    id: 1,
    name: "Container Name",
    isMove: false,
    align: "left",
  },

  {
    id: 2,
    name: "Weight",
    isMove: false,
    align: "right",
  },

  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];
