import { InstanceAxios } from "../hooks/axiosConfig";
import { IAddContainer } from "../models/IContainer";
import { IReturnCreate } from "../models/IReturnCreate";

export class ContainerService {
  public static getContainerList() {
    return InstanceAxios().get("/container/getContainer");
  }

  public static getCTrackList() {
    return InstanceAxios().get("/container/getSupplierContainerStatus");
  }

  public static returnCrateApi(data: IReturnCreate) {
    return InstanceAxios().post("/container/returnCrate", data);
  }

  public static getCrateDetailBySupplier(supplierId: string) {
    return InstanceAxios().get("/container/getCrateDetailBySupplier", {
      params: {
        supplierId: supplierId,
      },
    });
  }

  public static addNewContainer(data: IAddContainer) {
    return InstanceAxios().post("/container/addContainer", data);
  }
}
