import { InstanceAxios } from "../hooks/axiosConfig";
import { IaddOrUpdateReceiveOrder } from "../models/Branch/IProductAdd";

export class BranchService {
  public static getAllBranchApi() {
    return InstanceAxios().get("/branch");
  }

  public static getReceivedOrderDetailsByDateApi(date: string) {
    return InstanceAxios().get("/receiveOrder/getReceivedOrderDetailsByDate", {
      params: {
        processDate: date,
      },
    });
  }

  public static getAllReceivedProductsWithDetails(date: string) {
    return InstanceAxios().get(
      "/receiveOrder/getAllReceivedProductsWithDetails",
      {
        params: {
          processDate: date,
        },
      }
    );
  }

  public static addOrUpdateReceiveOrder(data: IaddOrUpdateReceiveOrder) {
    return InstanceAxios().post("/receiveOrder/addOrUpdateReceiveOrder", data);
  }

  public static getReportBranchManager(date?: string) {
    return InstanceAxios().get("/reports", {
      params: {
        processDate: date,
      },
    });
  }

  public static getDifferranceBranchManager() {
    return InstanceAxios().get("/reports/difference");
  }

  public static listProductsWithPricing() {
    return InstanceAxios().get("/reports/listProductsWithPricing");
  }

  public static updateRatePrice(productId: string, rate: number) {
    let data = {
      productId: productId,
      rate: rate,
    };
    return InstanceAxios().post(
      "/rate/updateSellingPriceByBranchManager",
      data
    );
  }
}
