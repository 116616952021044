import {
  TableRow,
  TableCell,
  Stack,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { IContainer } from "../../../../models/IContainer";
import { ICtrack } from "../../../../models/ICRack";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------
interface IProps {
  data: ICtrack;
  index: number;
}
// ----------------------------------------------------------------------

const TRow = ({ data, index }: IProps) => {
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left" sx={{ pl: 2 }}>
          <Link to={`/container-tracking/${data.supplierId}`}>
            {data.supplierName}
          </Link>
        </TableCell>{" "}
        <TableCell align="center">
          {" "}
          <Link to={`/container-tracking/${data.supplierId}`}>
            {data.totalToBeReturned}
          </Link>
        </TableCell>{" "}
        <TableCell align="right">
          <Link to={`/container-tracking/${data.supplierId}`}>
            <IconButton>
              <Iconify icon="weui:arrow-outlined" />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
