import { Add, List } from "./core";
import { MainHead } from "../../../core/bodyHead";
import { useState } from "react";

function ContainerTrackView() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <MainHead
        title="View Supplier Container"
        iconTitle="Add New "
        onClickHandler={onModelHandler}
      />

      <List />
    </>
  );
}

export default ContainerTrackView;
