import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Checkbox,
  IconButton,
  SelectChangeEvent,
  Button,
  CircularProgress,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { useNavigate } from "react-router-dom";
import { IPurchaseReport } from "../../../../models/Branch/IPurchaseReport";
import QuentityEnter from "../../../../core/QuentityEnter";
import { useState } from "react";
import { IUmoType } from "../../../../models/IUmoType";
// import WType from "../../../../core/WType";
import WType from "../../../../components/WType";
import ReactDOMServer from "react-dom/server";
import ProductBanner from "./PrintPdf/ProductBanner";
import { printReceipt } from "../../../../hooks/printReceipt";
import { IProductBanner } from "../../../../models/IProductBanner";
import { OutlineInput } from "../../../../core/InputField";
import { BranchService } from "../../../../services/Branch";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
// ----------------------------------------------------------------------

interface IProps {
  data: IPurchaseReport;
  index: number;
}

interface IData {
  id: number;
  name: string;
  type: string;
  price: string;
}

type SubmitData = {
  name: string;
  quantity: number;
};
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(data.sellingPrice);
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [pName, setPName] = useState(data.productName);
  const [pNameHindi, setPNameHindi] = useState(data.HName);
  const [wType, setWType] = useState<IUmoType>("PCS" === "PCS" ? "PCS" : "KG");
  const printPdfHandler = () => {
    const bannerData: IProductBanner = {
      name: pName,
      hindiName: pNameHindi,
      type: "KG",
      price: +quantity.toFixed(1),
    };
    // navigate("/print-item", { state: sendData });
    const receiptHtml = ReactDOMServer.renderToString(
      <ProductBanner data={bannerData} />
    );
    printReceipt(receiptHtml);
  };

  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => (prevQuantity = +value));
    }
  };

  const weightChange = (event: SelectChangeEvent) => {
    if (event.target.value === "KG" || event.target.value === "PCS") {
      setWType(event.target.value);
    }
  };

  const pNameHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPName(event.target.value);
  };

  const pNameHindiHandlerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPNameHindi(event.target.value);
  };

  const updateRate = async () => {
    try {
      setState({ ...state, loader: true });
      const response = await BranchService.updateRatePrice(
        data.productId,
        quantity
      );
      if (response.status === 200 && response.data) {
        setState({
          loader: false,
          tosted: true,
          message: "update sucessfully",
          severity: "success",
        });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Somthing went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };
  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
        // onClick={printPdfHandler}
      >
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.productName} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.productName}({data.HName})
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Stack
            direction={"row"}
            gap={"10px"}
            alignItems={"center"}
            height={"100%"}
            justifyContent={"center"}
          >
            <OutlineInput
              value={pName}
              type="text"
              label=""
              handleInputChange={pNameHandlerChange}
              error={undefined}
            />
            <OutlineInput
              value={pNameHindi}
              type="text"
              label=""
              handleInputChange={pNameHindiHandlerChange}
              error={undefined}
            />
          </Stack>
        </TableCell>
        <TableCell align="right">{data.sellingPrice.toFixed(2)}</TableCell>
        <TableCell align="left"> {data.UOM}</TableCell>

        <TableCell align="right">
          <Stack
            direction={"row"}
            gap={"10px"}
            // alignContent={"center"}
            alignItems={"center"}
            height={"100%"}
            justifyContent={"center"}
          >
            <QuentityEnter
              quantity={+quantity.toFixed(2)}
              increaseClickHandler={increaseClickHandler}
              decreaseClickHandler={decreaseClickHandler}
              InputHandler={InputHandler}
            />{" "}
            {/* <WType uRole={wType} handleChange={weightChange} /> */}
          </Stack>
        </TableCell>

        <TableCell align="right">
          <Stack direction={"row"} gap={"5px"} justifyContent={"end"}>
            <Button
              variant="contained"
              sx={{ height: "40px" }}
              disabled={false}
              // onClick={() => placeOrderHandler(data._id, quantity, date)}
              onClick={updateRate}
            >
              {!state.loader ? `Update` : <CircularProgress />}
            </Button>

            <Button
              variant="contained"
              sx={{ height: "40px" }}
              disabled={false}
              onClick={printPdfHandler}
              // onClick={() => placeOrderHandler(data._id, quantity, date)}
            >
              {/* {!state.loader ? `Update` : <CircularLoader />} */}
              Print
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default TRow;
