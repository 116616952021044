import { Box } from "@mui/material";
import { OutlineInput } from "../../../../core/InputField";
import { useState } from "react";
import { IState } from "../../../../models/IState";
import Contained from "../../../../core/Button/Contained";
import Tosted from "../../../../core/Tosted";
import CategoryList from "../CategoryList";
import { SelectChangeEvent } from "@mui/material/Select";
import { ProductServices } from "../../../../services/ProductServices";
import useIsProductStore from "../../../../store/isProduct";

type SubmitData = {
  name: string;
  HName: string;
  description: string;
  category: string;
};

interface IProps {
  handleClose: () => void;
}

const Form = ({ handleClose }: IProps) => {
  const [name, setName] = useState("");

  const [HName, setHName] = useState("");

  const [description, setDescription] = useState("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [nameValid, setNameValid] = useState({
    isValid: false,
    message: "Fill the Name",
  });

  const [HNameValid, setHNameValid] = useState({
    isValid: false,
    message: "Fill the Hindi Name",
  });

  const [desValid, setDesValid] = useState({
    isValid: false,
    message: "Fill the Description",
  });

  const [uRole, setURole] = useState("");
  const [sRoleValid, setSRoleValid] = useState({
    isValid: false,
    message: "Select An Category",
  });

  const { setActive } = useIsProductStore((state) => ({
    setActive: state.setActive,
  }));

  const nameHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (event.target.value === "") {
      setNameValid({ isValid: true, message: "Fill An Name" });
    } else {
      setNameValid({ isValid: false, message: "" });
    }
  };

  const HNameHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHName(event.target.value);
    if (event.target.value === "") {
      setHNameValid({ isValid: true, message: "Fill An Name" });
    } else {
      setHNameValid({ isValid: false, message: "" });
    }
  };

  const desHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
    if (event.target.value === "") {
      setDesValid({ isValid: true, message: "Fill An Area" });
    } else {
      setDesValid({ isValid: false, message: "" });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value == null) {
      setSRoleValid({
        isValid: true,
        message: "Select an role",
      });
    } else {
      setURole(event.target.value);
      setSRoleValid({
        isValid: false,
        message: "select an Role",
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setNameValid({
      isValid: name === "" ? true : false,
      message: "fill An Product Name",
    });

    setHNameValid({
      isValid: HName === "" ? true : false,
      message: "fill An Product Hindi Name",
    });

    setDesValid({
      isValid: description === "" ? true : false,
      message: "fill An Description Name",
    });

    setSRoleValid({
      isValid: uRole === "" ? true : false,
      message: "Select An Category",
    });

    if (name !== "" && description !== "" && uRole !== "") {
      onSubmit({ name, HName, description, category: uRole });
    }
  };

  const onSubmit = async ({
    name,
    HName,
    description,
    category,
  }: SubmitData) => {
    setState({
      ...state,
      loader: true,
    });

    try {
      const response = await ProductServices.addProductApi(
        name,
        HName,
        description,
        category
      );
      if (response.status === 201) {
        setState({
          tosted: true,
          loader: false,
          severity: "success",
          message: response.data.message,
        });
        setActive(true);
      } else {
        setState({
          tosted: true,
          loader: false,
          severity: "error",
          message: response.data.message,
        });
      }
    } catch (error: any) {
      setState({
        tosted: true,
        loader: false,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const { loader, tosted, message, severity } = state;

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <CategoryList
          uRole={uRole}
          handleChange={handleChange}
          error={sRoleValid.isValid ? sRoleValid.message : undefined}
        />
        <OutlineInput
          value={name}
          type="text"
          label="Name"
          handleInputChange={nameHandlerChange}
          error={nameValid.isValid ? nameValid.message : undefined}
          disabled={state.loader}
        />

        <OutlineInput
          value={HName}
          type="text"
          label="Hindi Name"
          handleInputChange={HNameHandlerChange}
          error={HNameValid.isValid ? HNameValid.message : undefined}
          disabled={state.loader}
        />

        <OutlineInput
          value={description}
          multiline
          type="text"
          label="Description"
          handleInputChange={desHandlerChange}
          error={desValid.isValid ? desValid.message : undefined}
          disabled={state.loader}
        />

        <Box textAlign={"center"} pt={1}>
          <Contained
            type="submit"
            variant="text"
            disabled={loader}
            loader={loader}
          >
            Add Product
          </Contained>
        </Box>
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default Form;
export { default as UpdateForm } from "./updateForm";
export { default as DeleteForm } from "./deleteForm";
